import IMask from 'imask';
import Validator from './functions';
import fields from './fields';
import './files';
import './pasteDisable';
import 'bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js';
import 'bootstrap-datepicker/dist/locales/bootstrap-datepicker.pl.min.js';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css';

$('.needs-validation').on('submit', function(event) {
	$(this).addClass('was-validated');
	if (!this.checkValidity()) {
		event.preventDefault();
		event.stopImmediatePropagation();
		$(this).trigger('submit-invalid');
		$(this).find('.invalid-feedback:visible:first')[0]?.scrollIntoView({ behavior: "smooth", block: 'center' });
	}
});

// Initialise the validator
fields.forEach(field => {
	// Foreach field with the same name selector
	const { name, validation } = field;
	const element = $(`[name="personal_data[${name}]"]`);
	element.each((i, field) => {
		if (field) {
			// Init IMask with IMask options or regex pattern
			const imask = validation.type === 'imask'
				? new IMask(field, validation.options)
				: new IMask(field, {mask: (value) => {}});
			field.form.addEventListener('reset', () => imask.masked.reset());
			// if validation.options == date, initialise datepicker
			if (validation.datepicker) {
				$(field).datepicker(validation.datepicker).on('changeDate', () => {
					imask.updateValue();
				});
			}
			// On IMask input change
			imask.on('accept', () => {
				Validator.setInvalid(field);
				if (validation.type === 'imask') Validator.isComplete(field, imask);
				if (validation.type === 'regex') Validator.pattern(field, validation.options);
				if (validation.handler) Validator.handler(field, imask.unmaskedValue, validation.handler);
				Validator.length(field);
				Validator.isEmpty(field);
			});
		}
	});
});